<template>
  <v-layout>
    {{ route.meta.null }}
    <v-main style="height: 100vh">
      <NuxtPage />
    </v-main>
  </v-layout>
</template>

<script setup>
const route = useRoute();
</script>

<style lang="scss" scoped></style>
